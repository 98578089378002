import './App.scss';
import React, {Component} from 'react';
import Dashboard from "./features/dashboard/Dashboard";

class App extends Component {
  render() {
    return (
      <Dashboard />
    );
  }
}


export default App;
